import React, {useState, useEffect, useRef} from 'react'
import { useParams } from 'react-router';
import styled from 'styled-components';
import Navbar from '../../Common/Navbar/Navbar'
import checkIfAuthed from '../../Common/utilFuncs/checkIfAuthed';
import {Stage, Layer, Image, Transformer, Rect} from 'react-konva';
import useImage from 'use-image';
import BuildConnector from './BuildConnector';
import Dropdown from './Dropdown';
import AggiungiElemento from './AggiungiElemento';
import TableElementi from './TableElementi';
import DeleteConnector from './DeleteConnector';
import {v4 as uuidv4} from 'uuid';
import Salva from './Salva'
const Spinner = require('react-spinkit')

const grid = 40;

const Container = styled.div`
  height: calc(100vh - var(--navbarHeight));
  display: grid;
  place-items: center;
`

const ContainerSchemi = styled.div`
  height: 85%;
  width: 80%;
  padding: .5rem;
  background-color: var(--clr-neutral-100);
  color: var(--clr-neutral-900);
  border-radius: .25rem;
`


function Oggetto({objects, setObject, index, isSelected, onSelect}) {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected])

  function dragEnd(e) {
    const setTargets = () => {
      const x = e.target.x();
      const y = e.target.y();

      let Coords = {};
      if (x < 0) {
        Coords.x = 0;
      } else {
        Coords.x = Math.round(x / grid) * grid;
      }

      if (y < 0) {
        Coords.y = 0
      } else {
        Coords.y = Math.round(y / grid) * grid;
      }
 
      return Coords;
    }
    const Coords = setTargets();

    e.target.to(Coords)

    let object = objects[index];
    object.x = Coords.x;
    object.y = Coords.y;

    setObject(object)
  }

  const handleTransform = (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    let object = {
      ...node.attrs,
      width: Math.max(grid, Math.round((node.width() * scaleX) / grid) * grid),
      height: Math.max(grid, Math.round((node.height() * scaleY) / grid) * grid)
    }

    setObject(object);
  }

  const [image] = useImage(objects[index].immagine)
   
  return (
    <>
      <Image
        image={image}
        onClick={onSelect} 
        ref={shapeRef}
        onDragEnd={dragEnd} 
        draggable 
        {...objects[index]}
        onTransformEnd={handleTransform}
      />
      {isSelected && (
        <Transformer
          rotationSnaps={[0, 90, 180, 270]}
          ref={trRef}
          resizeEnabled={true}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  )
}

export default function Add() {
  const {tipo} = useParams();
  const [user, setUser] = useState(null);
  const [elementi, setElementi] = useState(null);
  
  useEffect(() => {
    const coseAsync = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj)

      const elementiNew = await fetch("/api/schemi/objects").then(res => res.json());
      setElementi(elementiNew);
    }

    coseAsync();
  }, [tipo])
  return (
    <>
      <Navbar username={user && user.username}></Navbar>
      <Container>
        {
          user && elementi
          ? 
            <ContainerSchemi>
              <Schemi elementi={elementi} setElementi={setElementi} tipo={tipo}/>              
            </ContainerSchemi>
          :
            <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" style={{margin: ".25em"}} />
        }
      </Container>
    </>
  )
}

function Schemi({elementi, tipo, setElementi}) {
  const [selectedID, selectShape] = useState(null);
  const [objects, setObjects] = useState([]);

  const [dialogValues, setDialogValues] = useState(null);
  const [objIndex, setIndex] = useState([]);
  const [dialogValue, setDialogValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [eliminaConnettore, setEliminaConnettore] = useState(false);

  const [connectors, setConnectors] = useState([]);
  const [connector, setConnector] = useState(false);
  const [connectorQueue, setConnectorQueue] = useState({});

  const stageRef = useRef(null);
 
  const handleDeselect = (e) => {
    const clickedOnEmpty = e.target._id === 2;
    if (clickedOnEmpty) {
      selectShape(null);
    }
  }

  useEffect(() => {
    setConnectorQueue({});
  }, [connector])

  useEffect(() => {
    if (!dialogValue) return;

    if (!objIndex[1]) {
      setDialogValue(null);
      setConnectorQueue({object: objIndex[0], port: dialogValue.port})
      setDialogValues(null)
    } else {
      const connectorsNew = connectors.slice();
      let ObjectToBePushed = {};
      if (connectorQueue.object2 !== undefined && connectorQueue.port2 !== undefined ) {
        ObjectToBePushed = {...connectorQueue};
      } else {
        ObjectToBePushed = {...connectorQueue, object2: objIndex[0], port2: dialogValue.port};
      }
      ObjectToBePushed.details = dialogValue.details;
      connectorsNew.push(ObjectToBePushed); 
      setConnectors(connectorsNew);

      setDialogValue(null);
      setDialogValues(null);
      setConnector(false);
    }
  }, [dialogValue, connectorQueue, objIndex, objects, connectors])

  const handleSelect = (obj, index) => {
    if (!connector) return selectShape(index);

    if (Object.keys(connectorQueue).length < 1) {
      let elementName;

      let outputElements = {};
      
      for (let key of Object.keys(obj.connectorPorts)) {
        if (obj.connectorPorts[key].output) {
          outputElements[key] = obj.connectorPorts[key];
        }
      }
      if (Object.keys(outputElements).length < 1) return window.alert("Questo elemento non può fare da output");
      if (Object.keys(outputElements).length === 1) {
        window.alert("Hai selezionato l'elemento. Adesso selezionane un'altro")
        elementName = Object.keys(outputElements)[0]
      };
      if (Object.keys(outputElements).length > 1) {
        setDialogValues(outputElements)
        setIndex([index]);
        setOpenDialog(true);
        return;
      }
      let queue = {object: index, port: elementName}
      setConnectorQueue(queue);

      return;
    }
      
    let inputElements = {};
    
    for (let key of Object.keys(obj.connectorPorts)) {
      if (obj.connectorPorts[key].input) {
        inputElements[key] = obj.connectorPorts[key];
      }
    }
    if (Object.keys(inputElements).length < 1) return window.alert("Questo elemento non può fare da input");
    if (Object.keys(inputElements).length === 1) setConnectorQueue({...connectorQueue, object2: index, port2: Object.keys(inputElements)[0]});
    if (Object.keys(inputElements).length > 1) {
      setDialogValues(inputElements)
    }
    
    setIndex([index, true]);
    setOpenDialog(true);
  }

  return (
    <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
      <div>
        <AggiungiElemento elementi={elementi} objects={objects} setObjects={setObjects}/>
        <Salva stageRef={stageRef} objects={objects} tipo={tipo}></Salva>
        <button onClick={() => {setConnector(!connector)}}>{connector ? "Disabilita connettore elementi" : "Abilita connettore elementi"}</button>
        <button onClick={() => {setEliminaConnettore(true)}}>Elimina connettore</button>
        <button onClick={() => {
          if (selectedID === null) return;

          let indexesOfConnectors = [];

          connectors.forEach((connector, index) => {
            if (connector.object === selectedID || connector.object2 === selectedID) {
              indexesOfConnectors.push(index);
            }
          })

          const newObjects = objects.slice();
          newObjects.splice(selectedID, 1);

          const newConnectors = connectors.slice();
          for (let index of indexesOfConnectors) {
            newConnectors.splice(index, 1);
          }

          setConnectors(newConnectors);
          setObjects(newObjects);
        }}>Elimina Oggetto</button>
        <button onClick={async () => {
          const elementiNew = await fetch("/api/schemi/objects").then(res => res.json());
          console.log(elementiNew);
          setElementi(elementiNew);
          alert("Fatto!");
        }}>Aggiorna lista elementi</button>
      </div>
      <div style={{width: '100%', overflow: 'hidden', flexGrow: 1, display: 'grid', gridTemplateColumns: '3fr 1fr'}}>
        <div style={{overflow: 'scroll'}}>
          <Stage
            width={1280} 
            height={720}
            ref={stageRef}
          >
            <Layer>
              {/* Background */}
              <Rect width={1280} height={720} x={0} y={0} onMouseDown={handleDeselect} fill="white">
              </Rect>
              {
                objects.map((obj, index) => {
                  return (
                    <Oggetto 
                      isSelected={index === selectedID}
                      onSelect={() => handleSelect(obj, index)}
                      key={index} 
                      objects={objects} 
                      index={index} 
                      setObject={(newAttrs) => {
                        const objs = objects.slice();
                        objs[index] = newAttrs;
                        setObjects(objs);
                      }}
                    />
                  )
                })
              }
              { 
                connectors.map((e) => {
                  return <BuildConnector key={uuidv4()} objects={objects} element={e} />
                })
              }
            </Layer>
          </Stage>
        </div>
        <div style={{overflow: 'scroll'}}>
          <TableElementi objects={objects} elementi={elementi}/>
        </div>
      </div>
      {
        openDialog && 
        <Dropdown
          objects={dialogValues}
          setObject={setDialogValue}
          setOpenDialog={setOpenDialog}
          objIndex={objIndex}
        />
      }
      {
        (selectedID !== null && eliminaConnettore)
          ? <DeleteConnector setEliminaConnettore={setEliminaConnettore} setConnectors={setConnectors} connectors={connectors} selectedObject={selectedID} objects={objects}/>
          : null
      }
    </div>
  )  
}
