import React, { useRef } from 'react'
import Modal from "react-modal";

function Dropdown({connectors, setConnector, objects}) {
  const selectRef = useRef();
  
  const handleClick = () => {
    setConnector(selectRef.current.value);
  }

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      contentLabel="Seleziona connettore."
      style={{
        overlay: {
          position: 'fixed',
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-150px, -80px)',
          width: '400px',
          height: '160px',
          padding: '.5rem',
          textAlign: 'center'
        }
      }}
    >
      <p><b>Seleziona il collegamento che vuoi eliminare da questo oggetto</b></p>
      <select ref={selectRef}>
        {
          connectors.map((element, index) => {
            const value = `Oggetto <--> ${objects[element[element.primaryObject]].tipo}, ${element[element.primaryPort]}`;

            return <option key={index} value={element.index}>{value}</option>
          })
        }
      </select><br/><br/>
      <button type="button" onClick={handleClick}>Fatto</button>
    </Modal>  
  )
}


export default function DeleteConnector({selectedObject, connectors, setConnectors, objects, setEliminaConnettore}) {
  const filteredConnectors = connectors.filter((connector) => {return connector.object === selectedObject || connector.object2 === selectedObject});

  filteredConnectors.forEach(function(connector, index) {
    connectors.forEach((element, i) => {
      // Disabilitando eslint della prossima riga perchè stiamo comparando degli objects.
      // eslint-disable-next-line
      if (element == connector) {
        this[index].index = i;
      }
    })
    
    if (connector.object === selectedObject) {
      this[index].primaryObject = "object2";
      this[index].primaryPort = "port2";
    }
    if (connector.object2 === selectedObject) {
      this[index].primaryObject = "object";
      this[index].primaryPort = "port";
    }
  }, filteredConnectors);

  return (
    <>
      <Dropdown objects={objects} connectors={filteredConnectors} setConnector={(connector) => {
        if (connector === null) return;

        const newConnectors = connectors.slice();
        newConnectors.splice(connector, 1);
        setConnectors(newConnectors)
        setEliminaConnettore(false);
      }} />
    </>
  );
}