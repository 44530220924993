import React, { useState, useEffect } from 'react';
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';
import Navbar from '../Common/Navbar/Navbar';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
const Spinner = require('react-spinkit');

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: calc(100vh - var(--navbarHeight));
`

const DicosContainer = styled.div`
  width: 280px;
  height: 550px;
  background-color: var(--clr-neutral-100);
  color: var(--clr-neutral-900);
  padding: 10px 0 10px 10px;
`

const DicosBox = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
`

const DicoBoxHoverAnimation = keyframes`
  to {
    color: var(--clr-accent-300);
    background-color: var(--clr-neutral-200);
    border: 2px solid var(--clr-neutral-900);
  }
`

const DicoBox = styled.div`
  height: 150px;
  margin: 5px 0;
  text-align: center;
  color: var(--clr-neutral-200);
  background-color: var(--clr-neutral-900);
  border-radius: .5em;
  padding: 5px;

  :hover {
    cursor: pointer;
    animation-name: ${DicoBoxHoverAnimation};
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-direction: normal;
  }
`

export default function Delete() {
  const [user, setUser] = useState(null);
  const [dicos, setDicos] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj)
    }
    fetchUser();

    fetch("/api/dico").then(res => res.json())
      .then(res => {
        setDicos(res.reverse());
      })
  }, [])

  if (user && dicos) {
    return (
      <>
        <Navbar username={user.username} />
        <Container>
          <DicosContainer>
            <DicosBox>
              <Dicos dicos={dicos} />
            </DicosBox>
          </DicosContainer>
        </Container>
      </>
    )
  }

  return (
    <>
      <Navbar />
      <Container>
        <DicosContainer style={{ display: 'grid', placeItems: 'center' }}>
          <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" />
        </DicosContainer>
      </Container>
    </>
  )
}

function Dicos({ dicos }) {
  return (
    dicos.map(dico => {
      return <Dico key={uuidv4()} dico={dico} />
    })
  )
}

function Dico({ dico }) {
  function HandleClick(id) {
    let result = window.confirm("Sei sicuro di voler eliminare questa dichiarazione di conformità? Questa azione è irreversibile.");
  
    if (!result) return;

    fetch(`/api/dico/${id}`, {method: "DELETE"})
      .then( () => window.location.reload());
  }

  return (
    <DicoBox onClick={() => HandleClick(dico.dico_id)}>
      <h2>{dico.dico_id}</h2>
      <h3>{dico.tipo}</h3>
      <p><b>{dico.cliente}</b></p>
    </DicoBox>
  )
}
