import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./Login/login";
import Home from './Home/Home'
import New from "./New/New";
import Account from "./Account/Account";
import List from './List/List';
import Delete from './Delete/Delete'
import Schemi from './Schemi/Schemi'
import SchemiEditor from './Schemi/Editor/Editor'
import ElementoEditor from './Schemi/ElementoEditor/ElementoEditor';
import Schema from './Schemi/Schema'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/login"><Login /></Route>
        <Route path="/new"><New /></Route>
        <Route path="/account"><Account /></Route>
        <Route path="/list"><List /></Route>
        <Route path="/delete"><Delete /></Route>
        <Route exact path="/schemi"><Schemi /></Route>
        <Route exact path="/schemi/editor/:tipo" component={SchemiEditor}/>
        <Route exact path="/schemi/elementoEditor" component={ElementoEditor}/>
        <Route exact path="/schema/:index" component={Schema}/>
      </Switch>
    </Router>
  );
}

export default App;
