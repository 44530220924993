export default async function checkIfAuthed() {
	const auth = await fetch("/api/auth", {
		method: 'GET'
	})
		.then(res => res.json())
	if (auth.error) {
		window.location = "/login";
	} else {
		return auth;
	}
}
