import React, { useEffect, useState } from 'react'
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';
import styled, { keyframes } from 'styled-components';
import {v4 as uuidv4} from 'uuid';
import Navbar from '../Common/Navbar/Navbar';
import Dropdown from './Dropdown';
const Spinner = require('react-spinkit');

const Container = styled.div`
  height: calc(100vh - var(--navbarHeight));
  display: grid;
  place-items: center;
`

const SchemiContainer = styled.div`
  height: 550px;
  width: 280px;
  color: var(--clr-neutral-900);
  background: var(--clr-neutral-100);
  border-radius: .25em;
  position: relative;
  padding: .5em;
  padding-right: 0;
`

const SchemiAddButtonAnimation = keyframes`
  to {
    transform: translateX(430%);
    opacity: 1;
  }
`

const SchemiAddButton = styled.button`
  height: 64px;
  width: 64px;
  font-size: 3em;
  font-weight: bold;
  display: grid;
  place-items: center;
  border: none;
  border-radius: 50%;
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-300);
  transition: .5s;
  position: absolute;
  top: .15em;
  left: -1.35em;
  opacity: 0;
  animation: ${SchemiAddButtonAnimation} .3s ease-in-out forwards;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  :hover {
    background-color: var(--clr-neutral-100);
    color: var(--clr-accent-300);
    border: 1px solid var(--clr-neutral-900);
    text-shadow: 5px 5px 2px var(--clr-neutral-900);
    cursor: pointer;
  }
`

const SchemiBox = styled.div`
  height: 100%;
  overflow: scroll;
`

const SchemaButtonStyle = styled.button`  
  margin: .25rem;
  text-align: center;
  color: var(--clr-neutral-900);
  background-color: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-900);
  border-radius: .25em;
  width: 97%;

  :hover {
    color: var(--clr-accent-300);
    border-color: var(--clr-accent-300);
  }
`

function SchemaButton({ schema, index }) {
  const dataNonLocalizzata = new Date(schema.data)
  const dataLocalizzata = dataNonLocalizzata.toLocaleDateString();

  return (
    <SchemaButtonStyle onClick={() => {
      window.location = `/schema/${index}`
    }}>
      <h2>{dataLocalizzata}</h2>
      <h3>{schema.tipo}</h3>
    </SchemaButtonStyle>
  )
}

export default function Schemi() {
  const [user, setUser] = useState(null);
  const [schemi, setSchemi] = useState(null);
  const [objects, setObjects] = useState(null);
  const [object, setObject] = useState(null);
  
  useEffect(() => {
    const fetchUser = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj)
    }
    fetchUser();

    fetch("/api/schemi", { method: "GET" })
      .then(res => res.json())
      .then(res => setSchemi(res));

  }, [])

  useEffect(() => {
    if (!object) return;
    window.location = `/schemi/editor/${object}`;
  }, [object])

  const handleAdd = async () => {
    const actualObjects = await fetch('/api/schemi/objects').then(res => res.json());
    setObjects(actualObjects);
  }

  document.title = `DCenerator WEB - Schemi`

  if (user) {
    return (
      <>
        <Navbar username={user.username}></Navbar>
        {objects && <Dropdown objects={objects} setObject={setObject} />}
        <Container>
          <SchemiContainer>
            <SchemiAddButton onClick={handleAdd}>+</SchemiAddButton>
            <SchemiBox>
              <button onClick={() => window.location = "/schemi/elementoEditor"}>Editor Elementi</button>
              {
                schemi ?
                schemi.map((schema, index) => {
                  return (
                    <SchemaButton key={uuidv4()} schema={schema} index={index} />
                  )
                }) :
                <div style={{height: '100px', display: 'grid', placeItems: 'center'}}>
                  <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" style={{margin: ".25em"}} />
                </div>
              }
            </SchemiBox>
          </SchemiContainer>
        </Container>
      </>
    )
  } else {
    return (
      <>
        <Navbar></Navbar>
        <Container>
          <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" />
        </Container>
      </>
    )
  }
}
