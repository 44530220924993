import React, { useRef } from 'react';
import styled from 'styled-components';
import loginImage from './login.svg';

const ContainerLogin = styled.div`
  background-color: var(--clr-neutral-900);
  height: 100vh;
  display: grid;
  place-items: center;
`

const Form = styled.form`
  line-height: 1.7;
  color: var(--clr-neutral-900);
  
  border-radius: 0.25em;
  width: 280px;
  height: 550px;
  background: var(--clr-neutral-100);

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 35% 65%;
`

const Top = styled.img`
  max-height: 100%;
`

const Bottom = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 85% 15%;
  text-align: center;
`

const ErrorText = styled.p`
  color: var(--clr-accent-300);
`

function Login() {
  document.title = "DCenerator WEB - Login";
  const username = useRef();
  const password = useRef();
  const companyID = useRef();
  const error = useRef();

  async function FormSubmit(event) {
    event.preventDefault()

    // Our own form submission (Better, fitter, and more productive)
    fetch('/api/auth', {
      method: 'POST',
      body: JSON.stringify({
        username: username.current.value,
        password: password.current.value,
        companyID: companyID.current.value
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async (res) => {
        if (res.status === 200) {
          window.location = "/";
        } else {
          const resjson = await res.json();
          error.current.innerText = resjson.error;
        }
      })
  }

  return (
    <ContainerLogin>
      <Form onSubmit={FormSubmit}>
        <Top src={loginImage} alt="Signora che immette credenziali di accesso." />
        <Bottom>
          <div>
            <h1>Login</h1>
            <ErrorText ref={error}/>
            <div>
              <label htmlFor="companyID" className="required">Codice univoco ditta:</label><br />
              <input type="text" ref={companyID} name="companyID" id="companyID" required={true} />
            </div>
            <div>
              <label htmlFor="username" className="required">Nome utente:</label><br />
              <input type="text" ref={username} name="username" id="username" required={true} />
            </div>
            <div>
              <label htmlFor="password" className="required">Password:</label><br />
              <input type="password" ref={password} name="password" id="password" required={true} />
            </div>
          </div>
          <div>
            <input type="submit" value="Login" className="button" />
          </div>
        </Bottom>
      </Form>
    </ContainerLogin>
  )
}

export default Login;
