import React from 'react'
import styled from 'styled-components';

const Button = styled.button`
  border: 2px solid var(--clr-accent-300);
  border-radius: .5rem;
  color: var(--clr-accent-300);
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  float: right;
`

export default function AggiungiElemento({tipo, objects, stageRef}) {
  const handleSalva = async () => {
    const dataURLofPNG = stageRef.current.toDataURL();

    await fetch(`/api/schemi/${tipo}`, {
      method: "post", 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({objects: objects, dataURL: dataURLofPNG}),
    })

    window.location = "/schemi"
  }

  return (
    <>
      <Button type='button' onClick={handleSalva}>Salva</Button>
    </>
  )
}