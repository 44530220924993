import React, { useState, useEffect } from 'react'
import Navbar from '../Common/Navbar/Navbar'
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';
import styled from 'styled-components';

const ContainerHome = styled.div`
	line-height: 1.6;
	height: calc(100vh - var(--navbarHeight));
	display: flex;
	@media (max-width: 950px) {
		height: 100%;
		display: grid;
		overflow: hidden;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, 1fr);	
	}
`

const HomeCardBox = styled.div`
	flex: calc(100% / 3);
	display: grid;
	place-items: center;
	@media (max-width: 950px) {
		margin-top: 10px;	
	}
`

const Card = styled.div`
	background-image: var(--bg-image);
	background-size: contain;
	background-repeat: no-repeat;
	padding: 10rem 0 0;
	min-width: 30ch;
	max-width: 35ch;
	overflow: hidden;
	border-radius: .5rem;
`

const CardContent = styled.div`
	--padding: 1.5rem;
	padding: var(--padding);
	background: linear-gradient(
			hsl(0 0% 0% / 0),
			hsl(0 0% 0% / 0.4) 20%,
			hsl(0 0% 0% / 1)
	);
`

const CardTitle = styled.h2`
	position:relative;
	width: max-content;
	max-width: 100%;

	::after {
		content: "";
		background: var(--clr-accent-300);
		position:absolute;
		width: calc(100% + var(--padding));
		left: calc(0px - var(--padding));
		height: 4px;
		bottom: 0;
	}
`

export default function Home() {
	const [user, setUser] = useState(null);

	useEffect(() => {
		const fetchUser = async () => {
			const userObj = await checkIfAuthed();
			setUser(userObj)
		}
		fetchUser();
	}, [])

	if (user) {
		return (
			<>
				<Navbar username={user.username} />
				<ContainerHome>
					<HomeCardBox>
						<Card style={{ "--bg-image": 'url(/images/schemi.svg)' }}>
							<CardContent>
								<CardTitle>Gli schemi</CardTitle>
								<a href="/schemi" className="button">Avanti</a>
							</CardContent>
						</Card>
					</HomeCardBox>
					<HomeCardBox>
						<Card style={{ "--bg-image": "url('/images/listDocs.svg')" }}>
							<CardContent>
								<CardTitle>Dichiarazioni di conformità.</CardTitle>
								<a href="/list" className="button">Avanti</a>
							</CardContent>
						</Card>
					</HomeCardBox>
					<HomeCardBox>
						<Card style={{ "--bg-image": "url('/images/deleteDoc.svg')" }}>
							<CardContent>
								<CardTitle>Elimina una dichiarazione di conformità.</CardTitle>
								<a href="/delete" className="button">Avanti</a>
							</CardContent>
						</Card>
					</HomeCardBox>
				</ContainerHome>
			</>
		)
	} else {
		return null;
	}
}
