import React, {useState} from 'react'
import styled from 'styled-components';
import Modal from "react-modal";

const TableContainer = styled.div`
  padding: .25rem;
  overflow-y: scroll;
  width: 100%;
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 100%;
`

const TableRow = styled.div`
  margin: .25rem;
  border: 1px solid var(--clr-neutral-900);
  border-radius: .25rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  cursor: pointer;
`

const Button = styled.button`
  border: 2px solid var(--clr-accent-300);
  border-radius: 50%;
  color: var(--clr-accent-300);
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
`

function Selector({elementi, objects, setObjects, setIsOpen}) {
  const tipi = Object.keys(elementi);
  const [tipo, setTipo] = React.useState(tipi[0]);
  
  const handleClick = (element) => {
    setIsOpen(false);
    
    const newObjects = objects.slice();
    const newObject = {
      actualTipo: tipo,
      tipo: element,
      ...elementi[tipo][element][0],
      immagine: elementi[tipo][element][1],
      x: 0,
      y: 0,
      connectedTo: []
    }
    newObjects.push(newObject);
    setObjects(newObjects);
  }

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      contentLabel="Seleziona connettore."
      style={{
        overlay: {
          position: 'fixed',
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          height: '300px',
          padding: '.5rem',
          textAlign: 'center'
        }
      }}
    >
      <p><b>Seleziona l'oggetto che vuoi aggiungere</b></p><br/>
      <select onChange={(e) => {setTipo(e.target.value)}}>
        {
          tipi.map((element, index) => <option key={`${index}-select`} value={element}>{element}</option>)
        }
      </select>
      <TableContainer>
        <Table>
          {
            Object.keys(elementi[tipo]).map((element, index) => {
              return (
                <TableRow key={index} onClick={() => handleClick(element)}>
                  <div>
                    <img src={elementi[tipo][element][1]} style={{width: '100%'}} alt={element} />
                  </div>
                  <div style={{display: 'grid', placeItems: 'center', textAlign: 'center'}}>
                    <p>{element}</p>
                  </div>
                </TableRow>
              )
            })
          }
        </Table>
      </TableContainer>
      <button onClick={() => {setIsOpen(false)}}>Chiudi</button>
    </Modal>  
  )
}

export default function AggiungiElemento({elementi, objects, setObjects}) {
  const [isOpen, setIsOpen] = useState(null);

  const handleButtonElementoClick = () => {
    setIsOpen(true)
  }

  return (
    <>
      <Button onClick={handleButtonElementoClick} type='button'>+</Button>
      {
        isOpen && <Selector elementi={elementi} objects={objects} setObjects={setObjects} setIsOpen={setIsOpen} />
      }
    </>
  )
}
