import React, { useState, useEffect, useRef } from 'react'
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';
import styled from 'styled-components';
import Navbar from '../Common/Navbar/Navbar';
import image from './new.svg'

import PopFields from './popFields/PopFields';
import FirstSelect from './firstSelect/firstSelect';
import RespTecnicoSelect from './respTecnico/RespTecnico';

const NewContainer = styled.div`
  padding: 1rem;
	min-height: calc(100vh - var(--navbarHeight));
	display: grid;
	place-items: center;
`

const Form = styled.form`
  line-height: normal; 
  color: var(--clr-neutral-900);
  
  border-radius: 0.25em;
  width: 300px;
  height: 550px;
  background: var(--clr-neutral-100);

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 35% 65%;
`

const Top = styled.img`
	max-height: 100%;
`

const Bottom = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 85% 15%;
  text-align: center;
`

const FormRoot = styled.div`
  overflow: scroll;
  max-width: 100%;
`

const FormContent = styled.div`
  margin-top: 1.5em;
`

export default function New() {
	const [user, setUser] = useState(null);
	const [keys, setKeys] = useState(null);
	const [respTecnici, setRespTecnici] = useState(null)
	const [values, setValues] = useState(null);
	const firstSelect = useRef({ value: "elettrico" });

	useEffect(() => {
		checkIfAuthed().then(d => {
			setUser(d)
		})

		fetch("/api/getValues").then(res => res.json())
			.then(d => {
				setKeys(d)
			})

		fetch("/api/respTecnico", { method: "GET" }).then(res => res.json())
			.then(d => {
				setRespTecnici(d)
			})
	}, [])

	async function firstSelectChange() {
		const values = await fetch(`/api/getValues/${firstSelect.current.value}`).then(res => res.json());
		setValues(values);
	}

	if (user && keys && respTecnici) {
		return (
			<>
				<Navbar username={user.username} />
				<NewContainer>
					<Form action="/api/dico" method="post" encType="application/x-www-form-urlencoded" id="newForm">
						<Top src={image} alt="Signore che compila documenti" id="Image" />
						<Bottom>
							<FormRoot>
								<div>
									<h1>Nuovo</h1>
									<FirstSelect onChange={firstSelectChange} reference={firstSelect} keys={keys} />
									<RespTecnicoSelect respTecnici={respTecnici} />
								</div>
								<FormContent>
									<PopFields values={values} />
								</FormContent>
							</FormRoot>
							<div>
								<button type="submit" className="button">Genera</button>
							</div>
						</Bottom>
					</Form>
				</NewContainer>
			</>
		)
	} else {
		return null;
	}
}
