import React from 'react'
import styled from 'styled-components'

const Table = styled.div`
  display: grid;
  grid-template-columns: 100%;
`

const TableRow = styled.div`
  margin-top: .25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TableColumn = styled.div`
  text-align: center;
`

export default function TableElementi({objects, elementi}) {
  var uniqueTypes = Array.from(new Set(objects.map((e) => e.tipo)));

  var objectsByTipo = {};

  for (let type of uniqueTypes) {
    objectsByTipo[type] = objects.filter(object => object.tipo === type);
  }

  var numeratedObjects = {}

  for (let type of Object.keys(objectsByTipo)) {
    numeratedObjects[type] = objectsByTipo[type].length;
  }

  return (
    <Table>
      {
        Object.keys(numeratedObjects).map((element, index) => {
          let isArchitettonico = false;

          for (let tipo of Object.keys(elementi)) {
            if (elementi[tipo][element] && tipo.toLowerCase() === "architettonico") {
              isArchitettonico = true;
            }
          }

          if (isArchitettonico) return null;
          return (
            <TableRow key={index}>
              <TableColumn>
                {element}
              </TableColumn>
              <TableColumn style={{display: 'grid', placeItems: 'center'}}>
                {numeratedObjects[element]}  
              </TableColumn>
            </TableRow>
          )
        })
      }
    </Table>
  )
}
