import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function Tel(value) {
	return (
		<div className="formEntry" key={uuidv4()}>
			<label className={value.required ? "required" : ""} htmlFor={value.id}><b>{value.label}</b></label><br />
			<input required={value.required} type="tel" step="any" name={value.id} />
		</div>
	)
}
