import React, { useState, useEffect } from 'react';
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';
import Navbar from '../Common/Navbar/Navbar';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
const Spinner = require('react-spinkit');

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: calc(100vh - var(--navbarHeight));
`

const DicosContainer = styled.div`
  height: 550px;
  width: 280px;
  color: var(--clr-neutral-900);
  background: var(--clr-neutral-100);
  border-radius: .25em;
  position: relative;
  padding: .5em;
  padding-right: 0;
`

const DicosAddButtonAnimation = keyframes`
  to {
    transform: translateX(430%);
    opacity: 1;
  }
`

const DicosAddButton = styled.div`
  height: 64px;
  width: 64px;
  font-size: 3em;
  font-weight: bold;
  display: grid;
  place-items: center;
  border: none;
  border-radius: 50%;
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-300);
  transition: .5s;
  position: absolute;
  top: .15em;
  left: -1.35em;
  opacity: 0;
  animation: ${DicosAddButtonAnimation} .3s ease-in-out forwards;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  :hover {
    background-color: var(--clr-neutral-100);
    color: var(--clr-accent-300);
    border: 1px solid var(--clr-neutral-900);
    text-shadow: 5px 5px 2px var(--clr-neutral-900);
    cursor: pointer;
  }
`

const DicosBox = styled.div`
  height: 100%;
  overflow: scroll;
`

const DicoBoxHoverAnimation = keyframes`
  to {
    color: var(--clr-accent-300);
    border: 1px solid var(--clr-accent-300);
  }
`

const DicoBox = styled.div`
  height: 150px;
  margin: .25rem;
  text-align: center;
  border-radius: .5em;
  padding: 5px;

  border: 1px solid var(--clr-neutral-900);

  :hover {
    cursor: pointer;
    animation-name: ${DicoBoxHoverAnimation};
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-direction: normal;
  }
`

const Link = styled.a`
  text-decoration: none;
`

export default function List() {
  const [user, setUser] = useState(null);
  const [dicos, setDicos] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj)
    }
    fetchUser();

    fetch("/api/dico").then(res => res.json())
      .then(res => {
        setDicos(res.reverse());
      })
  }, [])

  if (user && dicos) {
    return (
      <>
        <Navbar username={user.username} />
        <Container>
          <DicosContainer>
            <Link href="/new">
              <DicosAddButton>+</DicosAddButton>
            </Link>
            <DicosBox>
              <Dicos dicos={dicos} />
            </DicosBox>
          </DicosContainer>
        </Container>
      </>
    )
  }

  return (
    <>
      <Navbar />
      <Container>
        <DicosContainer style={{ display: 'grid', placeItems: 'center' }}>
          <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" />
        </DicosContainer>
      </Container>
    </>
  )
}

function Dicos({ dicos }) {
  return (
    dicos.map(dico => {
      return <Dico key={uuidv4()} dico={dico} />
    })
  )
}

function Dico({ dico }) {

  function handleDicoBoxClick() {
    fetch(`/api/dico/${dico.dico_id}`)
      .then(res => res.json())
      .then(res => {
        const a = document.createElement('a');
        a.href=res.data.dicoFile;
	      a.download = true;
        a.click();
      })
  }

  return (
    <DicoBox onClick={handleDicoBoxClick}>
      <h2>{dico.dico_id}</h2>
      <h3>{dico.data.tipo}</h3>
      <p><b>{dico.data.cliente}</b></p>
    </DicoBox>
  )
}
