import React, { useRef, useState, useEffect } from 'react'
import Modal from "react-modal";
import {v4 as uuidv4} from 'uuid';

export default function Dropdown({objects, setObject, setOpenDialog, objIndex}) {
  const selectRef = useRef(null);
  const presetSelectRef = useRef(null);
  const coloreRef = useRef(null);
  const grandezzaRef = useRef(null);

  const [presets, setPresets] = useState(null);
  
  useEffect(() => {
    fetch('/api/schemi/lineePreset')
      .then((res) => res.json())
      .then((res) => setPresets(res))
  }, [])
  
  const handleClick = () => {
    const object = {}

    if (selectRef.current) {
      object.port = selectRef.current.value;
    }

    if (!objIndex[1]) {
      setOpenDialog(false);
      setObject(object);
      return;
    }

    if (presetSelectRef.current.value) {
      object.details = presets[presetSelectRef.current.value];
      setOpenDialog(false);
      setObject(object);
      return;
    }

    // Very big importante
    if (!coloreRef.current.value) {
      alert("Devi selezionare un colore per il preset");
      return;
    }
    if (!grandezzaRef.current.value) {
      alert("Devi selezionare una grandezza di linea per il preset")
      return;
    }

    const namePreset = prompt("Nome preset nuovo")
    let preset = {
      color: coloreRef.current.value,
      width: grandezzaRef.current.value 
    }
    fetch(`/api/schemi/lineaPreset/${namePreset}`, {
      method: "POST", 
      headers: {'Content-Type': 'application/json'}, 
      body: JSON.stringify(preset)
    })
      .then(() => {
        object.details = {...preset};
        setOpenDialog(false);
        setObject(object);
      })
  }

  const height = objects ? '455px' : '340px';

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      contentLabel="Seleziona connettore."
      style={{
        overlay: {
          position: 'fixed',
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
          height: height,
          padding: '.5rem',
          textAlign: 'center'
        }
      }}
    >
      {
        objects &&
          <>
            <p><b>Seleziona il collegamento che vuoi fare a questo oggetto</b></p>
            <select ref={selectRef}>
              {
                Object.keys(objects).map((element, index) => {
                  return <option key={index} value={element}>{element}</option>
                })
              }
            </select><br/><br/>
          </>
      }
      {
        objIndex[1] &&
          <>
            <p><b>Seleziona un preset:</b></p>
            <select ref={presetSelectRef}>
              <option value=""></option>
              {
                presets && Object.keys(presets).map((element) => {
                  return <option key={uuidv4()} value={element}>{element}</option>
                })
              }
            </select>
            <p><b><em>Oppure crea il tuo preset...</em></b></p>
            <p><b>Colore linea: </b></p>
            <input type="color" ref={coloreRef}/>
            <p><b>Grandezza linea: </b></p>
            <input type="number" ref={grandezzaRef}/><br/><br/>
          </>
      } 
      <button type="button" onClick={handleClick}>Fatto</button>
    </Modal>  
  )
}
