import React, { Component } from 'react'

class KeysOptions extends Component {
	componentDidMount() {
		this.props.onChange();
	}

	render() {
		return (
			this.props.keys.map((item, index) => {
				return (
					<option key={index} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</option>
				)
			})
		)
	}
}


export default function FirstSelect({onChange, reference, keys}) {
	return (
		<>
			<div>
				<label className="required" htmlFor="tipo">Per che tipo di impianto è la dichiarazione di conformità?</label>
			</div>
			<div>
				<select onChange={onChange} name="tipo" ref={reference}>
					<KeysOptions onChange={onChange} keys={keys}/>
				</select>
			</div>
		</>
	)
}
