import React from 'react'
import logo from './logo.png'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';

const Account = styled.a`
	text-decoration: none;
	color: var(--clr-neutral-900);
`

const LogoContainer = styled.div`
	flex: 72%;
	height: 100%;
	text-align: center;
`

const UsernameContainer = styled.div`
	flex: 28%;
	height: 100%;
`

const Logo = styled.img`
	max-height: 100%;
	margin-top: auto;
	margin-bottom: auto;
`

const Nav = styled.nav`
	display: flex;
	height: var(--navbarHeight);
	background-color: var(--clr-neutral-100);
	color: var(--clr-neutral-900);
	width: 100%;
	padding: 0;
	margin: 0;
`

library.add(fas);

export default function Navbar({ username }) {
	return (
		<Nav>
			<LogoContainer>
				<a href="/">
					<Logo style={{}} src={logo} alt="Logo, homepage link." />
				</a>
			</LogoContainer>
			<UsernameContainer>
				<p><Account href="/account"><FontAwesomeIcon icon={["fas", "user"]} /> {username}</Account></p>
			</UsernameContainer>
		</Nav>
	)
}
