import React, {useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import PopFields from '../../PopFields';

function CheckDepend({value}) {
  const checkBox = useRef(null);
  const [checked, setChecked] = useState(false)

  return (
    <div className="formEntry">
      <input onChange={() => setChecked(checkBox.current.checked)} type="checkbox" ref={checkBox} id={value.id} name={value.id}/> <label htmlFor={value.id}>{value.label}</label>
      <div>
        {checked && <PopFields values={value.options}/>}
      </div> 
    </div>
  )
}

export default function checkdepend(value) {
  return (
    <CheckDepend value={value} key={uuidv4()}/>
  )
}
