import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
  
const Table = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  height: max-content;
  width: 100%;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.value.columns.length}, calc(100% / ${props => props.value.columns.length}));
  grid-auto-flow: column;
  width: 100%;
  overflow: hidden;
  background-color: #dcdcdc;
  margin-bottom: 5px;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: calc(100% / ${props => props.value.columns.length}) calc(100% - calc(100% / ${props => props.value.columns.length}));
  grid-auto-flow: column;
  width: 100%;
  overflow: hidden;

  * {
    padding: 0;
    margin: 0 5px;
  }
`
const TableRowOther = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.value.columns.length}, calc(100% / ${props => props.value.columns.length - 1}));
  grid-auto-flow: column;
  width: 100%;
  overflow: hidden;

  * {
    padding: 0;
    margin: 0 5px;
  }
`

const TableColumn = styled.div`
  max-width: 100%;
`

const TableAdd = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.value.columns.length}, calc(100% / ${props => props.value.columns.length}));
  grid-auto-flow: column;
  height: max-content;
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin: .25em auto;

  * {
    padding: 0;
    margin: 0;
  }
`

const InputContainer = styled.div`
  max-width: 100%;
  margin: 0 5px;

  input {
    width: 100%;
    display: block
  }
`

const ButtonBox = styled.div`
  margin: 10px auto;
  width: 100%;
  text-align: center;
`

const Button = styled.button`
  background-color: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  border-radius: .25em;
  border: none;
  padding: .5em .85em;
  font-weight: bold;
  
  :hover {
    background-color: var(--clr-neutral-100);
    color: var(--clr-neutral-900);
    border: 1px solid var(--clr-neutral-900);
  }
`

function SectionedGrid({ value }) {

  const [GridData, setGridData] = useState([]);

  function aggiungiRow() {
    let row;

    const data = document.getElementById(`${value.id}_zone_add`).value;
    if (!data || data.length === 0) {
      return;
    }
    row = data;

    setGridData([...GridData, [row, []]])
  }

  function aggiungiData(index) {
    let columns = [];

    for (let i = 1; i < value.columns.length; i++) {
      let column = value.columns[i];

      let elementValue = document.getElementById(`${value.id}_${index}_${column}_add`);
      if (!elementValue || elementValue.value.length === 0) {
        return;
      }

      /*       
       * I just wanted to destroy some people's feelings by not using Array.push() 
       */
      columns[function (columns) {
        let counter = 0;
        // eslint-disable-next-line
        for (let column of columns) {
          counter++;
        }
        return counter;
      }(columns)] = elementValue.value;


    }

    let currentGridData = [...GridData];

    currentGridData[index][1].push(columns);
    setGridData(currentGridData);
  }

  return (
    <div className="formEntry">
      <label htmlFor={value.id}><b>{value.label}</b></label>
      <Table>
        <TableHeader value={value}>
          {
            value.columns.map(column => {
              return <p key={uuidv4()}><b>{column}</b></p>
            })
          }
        </TableHeader>
        {
          GridData.map((row, index) => {
            return (
              <TableRow value={value} key={uuidv4()}>
                <TableColumn>
                  <InputContainer>
                    <input type="text" readOnly={true} name={`${value.id}_section`} value={row[0]} />
                  </InputContainer>
                </TableColumn>
                <TableColumn>
                  {
                    row[1].map((rowingest) => {
                      return (
                        <TableRowOther value={value} key={uuidv4()}>
                          {
                            rowingest.map((column, i) => {
                              return (
                                <InputContainer key={uuidv4()}>
                                  <input type="text" readOnly={true} name={`${value.id}_${index}_${value.columns[i + 1]}`} value={column} />
                                </InputContainer>
                              )
                            })
                          }
                        </TableRowOther>
                      );
                    })
                  }
                  <TableRowOther value={value}>
                    {
                      value.columns.map((col, i) => {
                        if (i === 0) {
                          return null;
                        }

                        return (
                          <InputContainer key={uuidv4()}>
                            <input type={value.fieldsType[i]} id={`${value.id}_${index}_${col}_add`} />
                          </InputContainer>
                        )
                      })
                    }
                  </TableRowOther>
                  <ButtonBox>
                    <Button type="button" onClick={() => { aggiungiData(index) }}>+</Button>
                  </ButtonBox>
                </TableColumn>
              </TableRow>
            )
          })
        }
        <TableAdd value={value}>
          <InputContainer key={uuidv4()}>
            <input type={value.fieldsType[0]} id={`${value.id}_zone_add`} />
          </InputContainer>
        </TableAdd>
      </Table>
      <ButtonBox>
        <Button type="button" onClick={aggiungiRow}>+</Button>
      </ButtonBox>
    </div>
  )

}

export default function sectionedGrid(value) {
  return <SectionedGrid key={uuidv4()} value={value} />
}