import React, {useEffect, useState, useRef} from 'react';
import {Image, Circle, Rect, Stage, Layer} from 'react-konva';
import Navbar from '../../Common/Navbar/Navbar';
import checkIfAuthed from '../../Common/utilFuncs/checkIfAuthed';
import styled from 'styled-components';
import useImage from 'use-image';
import {v4 as uuidv4} from 'uuid';
const Spinner = require('react-spinkit');

export default function ElementoEditor() {
  const [user, setUser] = useState(null);
  const [tipi, setTipi] = useState(null);
  useEffect(() => {
    const asyncStuff = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj);
    }

    fetch("/api/schemi/tipi")
      .then(res => res.json())
      .then(res => setTipi(res));

    asyncStuff();
  }, [])

  return (
    <>
      <Navbar username={user ? user.username: null}/>
      {
        user && tipi ? <ActualElementoEditor tipiFake={tipi}/> : <Spins/>
      }
    </>
  )
}

const Container = styled.div`
  height: calc(100vh - var(--navbarHeight));
  display: grid;
  place-items: center;
`

const FormContainer = styled.div`
  height: 300px;
  width: 480px;
  display: flex;
  flex-direction: column;
  color: var(--clr-neutral-900);
  background: var(--clr-neutral-100);
  border-radius: .25em;
  position: relative;
  padding: .5em;
`

const Center = styled.div`
  flex-grow: 1;
  display: grid;
  place-items: center;
`

const FileName = styled.div`
  text-align: center;
`

const MainContent = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 55% 45%;
  input {
    max-width: 100%;
  }
  overflow: scroll;
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  width: 100%;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4));
  grid-auto-flow: column;
  width: 100%;
  overflow: hidden;
  background-color: #dcdcdc;
  margin-bottom: 5px;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(100% / 4));
  grid-auto-flow: column;
  width: 100%;
  overflow: hidden;

  * {
    padding: 0;
    margin: 0 5px;
  }
`

const ButtonBox = styled.div`
  margin: 10px auto;
  width: 100%;
  text-align: center;
`

const Button = styled.button`
  background-color: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  border-radius: .25em;
  border: none;
  padding: .5em .85em;
  font-weight: bold;
  
  :hover {
    background-color: var(--clr-neutral-100);
    color: var(--clr-neutral-900);
    border: 1px solid var(--clr-neutral-900);
  }
`

function Spins() {
  return (
    <Container>
      <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" />
    </Container>
  )
}

function ActualElementoEditor({tipiFake}) {
  const [step, setStep] = useState(0);
  const [file, setFile] = useState({});
  const [tipi, setTipi] = useState([...tipiFake]);
  const newType = useRef();
  const [fileData, setFileData] = useState({
    width: 184,
    height: 184,
    connectorPorts: {
      Sinistra: {x: 0, y: .5, input: true, output: true},
      Destra: {x: 1, y: .5, input: true, output: true},
      Alto: {x: .5, y: 0, input: true, output: true},
      Basso: {x: .5, y: 1, input: true, output: true}
    }
  });
  const [tipo, setTipo] = useState(tipiFake[0]);
  const fileInput = useRef(null);
  const fileLabel = useRef(null);

  const newNome = useRef(null);
  const newX = useRef(null);
  const newY = useRef(null);

  const selezionaFileSVG = async () => {
    let file = fileInput.current.files[0];
    const fileName = file.name.replace(/\..*$/, "");
    fileLabel.current.innerHTML = fileName;
		
    function readFileAsync(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
	
				reader.onload = () => {
					resolve(reader.result)
				}
	
				reader.onerror = reject;
	
				reader.readAsDataURL(file);
			})
		}
	
		const dataURL = await readFileAsync(file);
    setFile({name: fileName, dataURL: dataURL});
  }

  const [image] = useImage(file.dataURL);

  return (
    <Container>
      {
        step === 0 &&
        <>
          <input type="file" accept="image/svg+xml" ref={fileInput} onChange={selezionaFileSVG} style={{display: 'none'}}/> 
          <FormContainer>
            <Center>
              <label ref={fileLabel} dangerouslySetInnerHTML={{__html: file.name ? file.name : "Nessun file selezionato"}}/>
              <Button type="button" onClick={() => {fileInput.current.click()}}>Seleziona il file svg</Button>
            </Center>
            <div style={{textAlign: 'center'}}>
              <button type="button" onClick={() => {setStep(1)}}>Avanti</button>
            </div>
          </FormContainer> 
        </>
      }
      { step === 1 &&
        <>
          <FormContainer>
            <FileName>
              <label>Nome: </label><input type="input" defaultValue={file.name} onInput={(event) => {
                  setFile({name: event.target.value, dataURL: file.dataURL})
              }}/>
            </FileName>
            <MainContent>
              <div style={{overflow: 'scroll'}}>
                <label>Tipo:</label><select onChange={(event) => {
                  setTipo(event.target.value);
                }}>
                  {
                    tipi.map((element) => {
                      return <option value={element} key={uuidv4()} selected={element === tipo ? true : false}>{element}</option>
                    })
                  }
                </select>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 3fr'}}>
                  <button type='button' onClick={() => {
                    if (!newType.current.value) return;
                    setTipi([...tipi, newType.current.value]);
                    setTipo(newType.current.value);
                  }}>+</button>
                  <input type="text" ref={newType}/>
                </div>
                <label>Larghezza:</label><input type="number" defaultValue={fileData.height} onInput={
                    (event) => {
                      const object = Object.assign({}, fileData)
                      object.width = +event.target.value;
                      setFileData(object);
                    }
                  }/><br/>
                <label>Altezza</label><input type="number" defaultValue={fileData.width} onInput={
                    (event) => {
                    const object = Object.assign({}, fileData)
                    object.height = +event.target.value;
                    setFileData(object);
                  }
                } /><br/><br/>
                <Table>
                  <TableHeader>
                    <p><b>Nome</b></p>
                    <p><b>x</b></p>
                    <p><b>y</b></p>
                    <p><b>-</b></p>
                  </TableHeader>
                  {
                    Object.keys(fileData.connectorPorts).map((e, index) => {
                      const element = fileData.connectorPorts[e];
                      return (
                        <TableRow key={uuidv4()}>
                          <input type="text" defaultValue={e} onBlur={(event) => {
                            const object = Object.assign({}, fileData);
                            delete object.connectorPorts[e];

                            object.connectorPorts[event.target.value] = element;
                            setFileData(object);
                          }}/>
                          <input type="number" step="any" defaultValue={element.x} onBlur={(event) => {
                            const object = Object.assign({}, fileData);
                            object.connectorPorts[e].x = +event.target.value;
                            setFileData(object);
                          }}/>
                          <input type="number" step="any" defaultValue={element.y} onBlur={(event) => {
                            const object = Object.assign({}, fileData);
                            object.connectorPorts[e].y = +event.target.value;
                            setFileData(object);
                          }}/>
                          <button type="button" onClick={() => {
                            const object = Object.assign({}, fileData);
                            delete object.connectorPorts[e];

                            setFileData(object);
                          }}>-</button>
                        </TableRow>
                      )
                    })
                  }
                  <TableRow style={{marginTop: '1rem'}}>
                    <input type="text" ref={newNome}/>
                    <input type="number" step="any" ref={newX}/>
                    <input type="number" step="any" ref={newY}/>
                  </TableRow>
                  <ButtonBox>
                    <Button type="button" onClick={() => {
                      const object = Object.assign({}, fileData);

                      const adding = {
                        x: newX.current.value,
                        y: newY.current.value,
                        output: true,
                        input: true,
                      }

                      object.connectorPorts[newNome.current.value] = adding;
                      setFileData(object);
                    }}>+</Button>
                  </ButtonBox>
                </Table>
              </div>
              <div>
                <Stage height={200} width={200}>
                  <Layer>
                    <Image image={image} width={fileData.width} height={fileData.height} x={8} y={8}/>
                    <Rect x={8} y={8} width={fileData.width} height={fileData.height} stroke="black" strokeWidth={1}></Rect>
                    {
                      Object.keys(fileData.connectorPorts).map((e, index) => {
                        const element = fileData.connectorPorts[e];
                        return (
                          <Circle key={index} 
                            x={8 + (element.x * fileData.width)} 
                            y={8 + element.y * fileData.height} 
                            radius={4} 
                            fill="red"
                            draggable 
                            onDragEnd={(event) => {
                              const x = (event.target.x() - 8) / fileData.width ;
                              const y = (event.target.y() - 8) / fileData.height;
                              const object = Object.assign({}, fileData);
                              object.connectorPorts[e] = {...object.connectorPorts[e], x: x, y: y};
                              setFileData(object)
                            }}
                            />
                        );
                      })
                    }
                  </Layer>
                </Stage>
              </div>
            </MainContent>
            <div style={{textAlign: 'center'}}>
              <button type="button" onClick={async () => {
                const object = {}

                object[file.name] = [fileData, file.dataURL];
                await fetch(`/api/schemi/object/${tipo}`, {
                  method: "POST", 
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify(object)
                });

                window.location = "/"
              }}>Invia</button>
            </div>
          </FormContainer>
        </>
      }
    </Container>
  );
}
