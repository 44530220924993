import radio from './fieldTypes/radio/radio'
import select from './fieldTypes/select/select';
import address from './fieldTypes/address/address';
import checkother from './fieldTypes/checkother/checkother';
import filelist from './fieldTypes/filelist/filelist'
import number from './fieldTypes/number/number'
import text from './fieldTypes/text/text'
import radioother from './fieldTypes/radioother/radioother'
import grid from './fieldTypes/grid/grid'
import sectioned_grid from './fieldTypes/sectioned_grid/sectioned_grid';
import checkdepend from './fieldTypes/checkdepend/checkdepend';
import email from './fieldTypes/email/email';
import tel from './fieldTypes/tel/tel';
import fixedgrid from './fieldTypes/fixedgrid/fixedgrid';

const types = {};
types.radio = radio;
types.select = select;
types.address = address;
types.checkother = checkother;
types.filelist = filelist;
types.number = number;
types.text = text;
types.radioother = radioother;
types.grid = grid;
types.sectioned_grid = sectioned_grid;
types.checkdepend = checkdepend;
types.tel = tel;
types.email = email;
types.fixedgrid = fixedgrid;

export default function PopFields({ values }) {
	if (!values) {
		return null;
	}

	return (
		values.map((value, index) => {
			return types[value.type](value, index)
		})
	)
}
