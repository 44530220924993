import React, { useRef } from 'react'
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const AltroText = styled.input`
	max-width: 50%;
	margin-left: .5em;
`

function Options({ optionsArr, value }) {
	const inputAltro = useRef();
	const altroText = useRef();

	return (
		optionsArr.map((option, index) => {
			if (option !== "Altro") {
				return (
					<div key={uuidv4()}>
						<input type={value.type} name={value.id} value={option} required={value.required} id={`${value.id}_option-${index}`} />
						<label htmlFor={`${value.id}_option-${index}`}> {option}</label>
					</div>
				)
			} else {
				function handleInputAltroText() {
					inputAltro.current.value = altroText.current.value;
				}
				return (
					<div key={uuidv4()}>
						<input ref={inputAltro} type={value.type} name={value.id} required={value.required} id={`${value.id}_option-${index}`} />
						<label htmlFor={`${value.id}_option-${index}`}> {option}</label>
						<AltroText ref={altroText} type="text" onInput={handleInputAltroText} />
					</div>
				);
			}
		})
	)
}

export default function radio(value) {
	return (
		<div className="formEntry" key={uuidv4()}>
			<label className={value.required ? "required" : ""} htmlFor={value.id}><b>{value.label}</b></label>
			<div>
				<Options optionsArr={value.options} value={value} />
			</div>
		</div>
	)
}
