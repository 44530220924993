import React, {useRef} from 'react'
import { v4 as uuidv4 } from 'uuid'

export default function Filelist(value) {
	return (
		<div className="formEntry" key={uuidv4()}>
			<label><b>{value.label}</b></label>
			<Options values={value.options} />
		</div>
	)
}

function Options({ values }) {
	return (
		values.map(value => {
			return <Option key={uuidv4()} value={value} />
		})
	)
}

function Option({ value }) {
	const hiddenBase64 = useRef(null);
	const hiddenFileInput = useRef(null);
	const buttonFile = useRef(null);

	function handleClick(event) {
		hiddenFileInput.current.click();
	}

	async function handleFileChanging(event) {
		
		function readFileAsync(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
	
				reader.onload = () => {
					resolve(reader.result)
				}
	
				reader.onerror = reject;
	
				reader.readAsDataURL(file);
			})
		}
	
		const dataURL = await readFileAsync(hiddenFileInput.current.files[0]);
		hiddenBase64.current.value= dataURL;

		buttonFile.current.innerHTML = `Seleziona ${value.label}.<br><br> Selezionato: <br><b>${hiddenFileInput.current.files[0].name}</b>`;
	}

	function handleClear () {
		hiddenFileInput.current.value = null;
		buttonFile.current.innerHTML = `Seleziona ${value.label}.`
		hiddenBase64.current.value = null;
	}

	return (
		<>
			<div style={{ "marginTop": ".5em"}}>
					<button ref={buttonFile} type="button" onClick={handleClick} className="button">Seleziona {value.label}</button>
					<button type="button" onClick={handleClear} style={{"marginTop": "2px"}} className="button">Elimina selezionato</button>
			</div>
			<input onChange={handleFileChanging} type="file" accept={value.accept} style={{ display: 'none' }} ref={hiddenFileInput} />
			<input name={value.id} type="hidden" ref={hiddenBase64} /> 
		</>
	)
}
