import {Line} from 'react-konva';
import Konva from 'konva';

export default function BuildConnector({objects, element}) {
  //Non ci credo neanche io ma sta cosa funziona. Che bello il spaghetti code

  const konvaLine1 = new Konva.Line({
    x: objects[element.object2].x,
    y: objects[element.object2].y,
    points: [
      objects[element.object2].connectorPorts[element.port2].x * objects[element.object2].width, 
      0, 
      objects[element.object2].connectorPorts[element.port2].x * objects[element.object2].width, 
      objects[element.object2].connectorPorts[element.port2].y * objects[element.object2].height
    ],
    rotation: objects[element.object2].rotation
  })

  const positions1 = konvaLine1.getAbsoluteTransform().point({
    x: objects[element.object2].connectorPorts[element.port2].x * objects[element.object2].width, 
    y: objects[element.object2].connectorPorts[element.port2].y * objects[element.object2].height
  })
  
  const konvaLine2 = new Konva.Line({
    x: objects[element.object].x,
    y: objects[element.object].y,
    points: [
      objects[element.object].connectorPorts[element.port].x * objects[element.object].width, 
      0, 
      objects[element.object].connectorPorts[element.port].x * objects[element.object].width, 
      objects[element.object].connectorPorts[element.port].y * objects[element.object].height
    ],
    rotation: objects[element.object].rotation
  })

  const positions2 = konvaLine2.getAbsoluteTransform().point({
    x: objects[element.object].connectorPorts[element.port].x * objects[element.object].width, 
    y: objects[element.object].connectorPorts[element.port].y * objects[element.object].height
  })
       
  return (
    <Line
      points={[positions2.x, positions2.y, positions1.x, positions1.y]}
      fill={element.details.color}
      stroke={element.details.color}
      strokeWidth={element.details.width}
    />
  )
}