import React, { useRef } from 'react'
import Modal from "react-modal";

export default function Dropdown({objects, setObject}) {
  const selectRef = useRef();
  
  const handleClick = () => {
    setObject(selectRef.current.value);
  }

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      contentLabel="Seleziona tipo schema."
      style={{
        overlay: {
          position: 'fixed',
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-150px, -80px)',
          width: '300px',
          height: '160px',
          padding: '.5rem',
          textAlign: 'center'
        }
      }}
    >
      <p><b>Seleziona il tipo di schema che vuoi fare</b></p>
      <select ref={selectRef}>
        {
          Object.keys(objects).map((element, index) => {
            return <option key={index} value={element}>{element}</option>
          })
        }
      </select><br/><br/>
      <button type="button" onClick={handleClick}>Fatto</button>
    </Modal>  
  )
}