import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const OtherFieldSelect = styled.select`
	max-width: 30%;
`

const OtherFieldInput = styled.input`
	max-width: 30%;
`

export default function Checkother(value) {
	return (
		<div className="formEntry" key={uuidv4()}>
			<label className={value.required ? "required" : null} htmlFor={value.id}><b>{value.label}</b></label>
			<Options required={value.required} value={value} />
		</div>
	)
}

function Options({ required, value }) {
	return (
		value.options.map((stuff, index) => {
			return (
				<Option key={uuidv4()} required={required} stuff={stuff} index={index} value={value} />
			)
		})
	)
}

function Option({ required, value, stuff, index }) {
	return (
		<div key={uuidv4()}>
			<input required={required} type="radio" value={`option-${index + 1}`} name={value.id} id={`${value.id}_option-${index + 1}`} />
			<label htmlFor={`${value.id}_option-${index + 1}`}> {stuff.label} </label>
			{stuff.field && <Field stuff={value.id} value={stuff} />}
		</div>
	)
}

function Field({ stuff, value }) {
	if (value.field.type === "select") {
		return (
			<OtherFieldSelect name={stuff}>
				<FieldOptions values={value.field.options} />
			</OtherFieldSelect>
		)
	} else if (value.field.type === "text" || value.field.type === "number") {
		return (
			<OtherFieldInput defaultValue=" " type={value.field.type} step="any" name={stuff} />
		)
	}
}

function FieldOptions({ values }) {
	return (
		values.map(value => {
			return (
				<option key={uuidv4()} value={value}>{value}</option>
			)
		})
	)
}
