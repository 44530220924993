import React from 'react';
import {v4 as uuidv4} from 'uuid';

function RespTecnici({respTecnici}) {
	return (
		respTecnici.map(respTecnico => {
			return (
				<option key={uuidv4()} value={respTecnico.username}>{respTecnico.username}</option>
			)			
		})
	)
}

export default function RespTecnico({respTecnici}) {
	return (
		<div>
			<div>
				<label className="required" htmlFor="resp_tecnico">Responsabile Tecnico:</label>
			</div>
			<select name="resp_tecnico" required={true}>
				<RespTecnici respTecnici={respTecnici}/>
			</select>
		</div>
	) 
}
