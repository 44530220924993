import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router';
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';

export default function Schema() {
  const {index} = useParams();

  const [schema, setSchema] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj)
    }
    fetchUser();
   
    fetch("/api/schemi")
      .then(res => res.json())
      .then(res => setSchema(res[index].dataURL))
  }, [index])

  return (
    user && <iframe title="Schema" src={schema} width={window.innerWidth - 12} height={window.innerHeight - 12} />
  )
}