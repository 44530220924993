import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

function Options({ optionsArr, clienti }) {

	if (optionsArr === "clienti") {
		return (
			clienti.map(cliente => {
				return (
					<option key={uuidv4()} value={cliente.company}>{cliente.company}</option>
				)
			})
		)
	} else {
		return (
			optionsArr.map(option => {
				return (
					<option key={uuidv4()} value={option}>{option}</option>
				)
			})
		)
	}
}

export default function Select(value) {
	const [clienti, setClienti] = useState([]);

	useEffect(() => {
		fetch('/api/getClienti').then(res => res.json())
			.then(d => {
				setClienti(d)
			})

	}, [])

	return (
		<div className="formEntry" key={uuidv4()}>
			<label className={value.required ? "required" : ""} htmlFor={value.id}><b>{value.label}</b></label>
			<select name={value.id} required={value.required}>
				<Options optionsArr={value.options} clienti={clienti} />
			</select>
		</div>
	)
}
