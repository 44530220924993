import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid'

const AddressProvinciaComune = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr 3fr;
`

const AddressOther = styled.div`
	display:grid;
	grid-auto-flow: row;
	grid-template-rows: repeat(5, 1fr);
	div {
		margin-top: 2.5px;
	}
`

const AddressInput = styled.input`
	max-width: 30%;
`

export default function Address(value) {
	const [comuni, setComuni] = useState(null);
	useEffect(() => {
		let isSubscribed = true;
		fetch("https://raw.githubusercontent.com/matteocontrini/comuni-json/master/comuni.json").then(res => res.json())
			.then(comuni => {
				if (isSubscribed) {
					var comuniPiemontesi = [];
					for (let comune of comuni) {
						if (comune.regione.nome === "Piemonte") {
							comuniPiemontesi.push(comune);
						}
					}
	
					var provincie = [];
					for (let comune of comuniPiemontesi) {
						if (!provincie.includes(comune.sigla)) {
							provincie = [...provincie, comune.sigla];
						}
					}
	
					var comuniPerProvincia = {};
					
					for (let provincia of provincie) {
						comuniPerProvincia[provincia] = [];
					}
					for (let comune of comuniPiemontesi) {
						comuniPerProvincia[comune.sigla].push(comune);
					}
	
					setComuni(comuniPerProvincia);
				}
			})
		return () => {isSubscribed = false}
	}, []) 

	var provincia;
	const provinciaSelect = useRef();

	function handleChangeProvincia() {
		provincia = provinciaSelect.current.value;
		const comuneSelect = document.getElementById(`${value.id}_comune`);
		comuneSelect.innerHTML = "";
		comuni[provincia].forEach(element => {
			const comune = document.createElement('option');
			comune.value = element.nome;
			comune.innerText = element.nome
			comuneSelect.appendChild(comune);
		});
	}

	if (comuni) {
		if (!provincia) {
			provincia = Object.keys(comuni)[0];
		}
		return (
			<div className="formEntry" key={uuidv4()}>
				<div>
					<label className={value.required ? "required" : ""} htmlFor={value.id}><b>{value.label}</b></label>
				</div>
				<AddressProvinciaComune>
					<select required={value.required} defaultValue={provincia} ref={provinciaSelect} onChange={handleChangeProvincia} name={value.id}>
						<ProvinciaOptions values={comuni}/>
					</select>
					<select id={`${value.id}_comune`} required={value.required} name={value.id}>
						<ComuneOptions required={value.required} values={comuni} provincia={provincia}/>
					</select>
				</AddressProvinciaComune>
				<AddressOther>
					<div>
						<label htmlFor={`${value.id}_via`} className={value.required ? "required" : ""}>Via: </label>
						<AddressInput name={value.id} type="text" id={`${value.id}_via`} required={value.required}/>
					</div>
					<div>
						<label htmlFor={`${value.id}_n_civico`} className={value.required ? "required" : ""}>Numero Civico: </label>
						<AddressInput name={value.id} type="text" id={`${value.id}_n_civico`} required={value.required}/>
					</div>
					<div>
						<label htmlFor={`${value.id}_scala`}>Scala: </label>
						<AddressInput name={value.id} type="text" id={`${value.id}_scala`}/>
					</div>
					<div>
						<label htmlFor={`${value.id}_piano`}>Piano: </label>
						<AddressInput name={value.id} type="text" id={`${value.id}_piano`}/>
					</div>
					<div>
						<label htmlFor={`${value.id}_interno`}>Interno: </label>
						<AddressInput name={value.id} type="text" id={`${value.id}_interno`}/>
					</div>
				</AddressOther>
			</div>
		)
	}	else {
		return null;
	}	
}

function ComuneOptions({provincia, values}) {
	return (
		values[provincia].map(value => {
			return <option key={uuidv4()} value={value.nome}>{value.nome}</option>
		})
	)
}

function ProvinciaOptions({values}) {
	return (
		Object.keys(values).map(value => {
			return <option key={uuidv4()} value={value}>{value}</option>
		})
	)
} 
