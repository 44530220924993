import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components'

function Grid({ value }) {
  const Table = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    height: max-content;
    width: 100%;
  `

  const TableHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(${value.columns.length}, calc(100% / ${value.columns.length}));
    grid-auto-flow: column;
    width: 100%;
    overflow: hidden;
    background-color: #dcdcdc;
    margin-bottom: 5px;
    text-align: center;

    p {
      padding: 0;
      margin: 0;
    }
  `

  const TableRow = styled.div`
    display: grid;
    grid-template-columns: repeat(${value.columns.length}, calc(100% / ${value.columns.length}));
    grid-auto-flow: column;
    width: 100%;
    overflow: hidden;

    * {
      padding: 0;
      margin: 0 5px;
    }
  `

  const TableColumn = styled.div`
    overflow-x: scroll;
  `

  const TableAdd = styled.div`
    display: grid;
    grid-template-columns: repeat(${value.columns.length}, calc(100% / ${value.columns.length}));
    grid-auto-flow: column;
    height: max-content;
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin: .25em auto;

    * {
      padding: 0;
      margin: 0;
    }
  `

  const InputContainer = styled.div`
    max-width: 100%;
    margin: 0 5px;

    input {
      width: 100%;
      display: block
    }
  `

  const ButtonBox = styled.div`
    margin: 10px auto;
    width: 100%;
    text-align: center;
  `

  const Button = styled.button`
    background-color: var(--clr-accent-300);
    color: var(--clr-neutral-100);
    border-radius: .25em;
    border: none;
    padding: .5em .85em;
    font-weight: bold;
    
    :hover {
      background-color: var(--clr-neutral-100);
      color: var(--clr-neutral-900);
      border: 1px solid var(--clr-neutral-900);
    }
  `

  const [GridData, setGridData] = useState([]);

  function aggiungi() {
    let addingData = {};

    for (let column of value.columns) {
      const data = document.getElementById(`${value.id}_${column}_add`).value;
      if (!data || data.length === 0) {
        return;
      }
      addingData[column] = data;
    }

    setGridData([...GridData, addingData])
  }

  return (
    <div className="formEntry">
      <label htmlFor={value.id}><b>{value.label}</b></label>
      <Table>
        <TableHeader>
          {
            value.columns.map(column => {
              return <p key={uuidv4()}><b>{column}</b></p>
            })
          }
        </TableHeader>
        {
          GridData.map(row => {
            return (
              <TableRow key={uuidv4()}>
                {
                  Object.keys(row).map(col => {
                    return (
                      <TableColumn key={uuidv4()}>
                        <InputContainer>
                          <input type="text" readOnly={true} name={`${value.id}_${col}`} value={row[col]} />
                        </InputContainer>
                      </TableColumn>
                    )
                  })
                }
              </TableRow>
            )
          })
        }
        <TableAdd>
          {
            value.columns.map((col, index) => {
              return (
                <InputContainer key={uuidv4()}>
                  <input type={value.fieldsType[index]} id={`${value.id}_${col}_add`} />
                </InputContainer>
              )
            })
          }
        </TableAdd>
      </Table>
      <ButtonBox>
        <Button type="button" onClick={aggiungi}>+</Button>
      </ButtonBox>
    </div>
  )
}

export default function grid(value) {
  return (
    <Grid value={value} key={uuidv4()} />
  )
}