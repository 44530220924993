import React from 'react'
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';

const OtherCheckFieldInput = styled.input`
	max-width: 30%;
`

const OtherCheckFieldSelect = styled.select`
	max-width: 30%;
`

export default function Checkother(value) {
	return (
		<div className="formEntry" key={uuidv4()}>
			<label className={value.required ? "required" : null} htmlFor={value.id}><b>{value.label}</b></label>
			<Options values={value.options}/>
		</div>
	)
}

function Options({values}) {
	return (
		values.map((value) => {
			return (
				<Option key={uuidv4()} value={value}/>
			)
		})
	)
}

function Option({value}) {
  const [isFieldOpen, setIsFieldOpen] = React.useState(false);

	return (
		<div key={uuidv4()}>
			<input type="checkbox" name={value.id} checked={isFieldOpen} onChange={(e) => {setIsFieldOpen(!isFieldOpen)}} id={value.id}/>
			<label htmlFor={value.id}> {value.label} </label>
			{isFieldOpen && (value.field && <Field value={value}/>)}
		</div>
	)
}

function Field({value}) {
	if (value.field.type === "select") {
		return (
			<OtherCheckFieldSelect name={value.id}>
				<FieldOptions values={value.field.options}/>
			</OtherCheckFieldSelect>
		)
	} else if (value.field.type === "text" || value.field.type === "number"){
		return (
			<OtherCheckFieldInput type={value.field.type} step="any" name={value.id}/>
		)
	} else if (value.field.type === "checkbox") {
    return <Checkbox value={value}/>
  }
}

function Checkbox({value}) {
  return (
    <div>
      {
        value.field.options.map((e, i) => {
          return (
            <div key={`${i}-normatecnica`}>
              <input type="checkbox" value={e} id={`${value.name}-1`} name={value.field.id} />
              <label htmlFor={`${value.name}-1`}>{e}</label>
            </div>
          )
        })
      }
    </div>
  )
}

function FieldOptions({values}) {
	return (
		values.map(value => {
			return (
				<option key={uuidv4()} value={value}>{value}</option>
			)
		})
	)
}
