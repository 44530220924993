import React from 'react';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';

const Table = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  height: max-content;
  width: 100%;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-auto-flow: column;
  width: 100%;
  background-color: #dcdcdc;
  margin-bottom: 5px;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-auto-flow: column;
  width: 100%;
  overflow: hidden;

  * {
    padding: 0;
    margin: 0 5px;
  }
`

const TableColumn = styled.div`
  overflow-x: scroll;
  text-align: left;

  input[type="radio"], input[type="checkbox"] {
    display: inline-block;
  }

  input[type="text"] {
    display: block;
    width: calc(100% - 10px);
  }
`

function FixedGrid({value}) {

  return (
    <Table>
      <TableHeader>
        <p><b>{value.label[0]}</b></p>
        <p><b>{value.label[1]}</b></p>
      </TableHeader>
      {
        value.options.map(val => {
          return (
            <TableRow key={uuidv4()}>
              <TableColumn>
                {val.type === "checkbox" ? <Check value={val}/> : <Text value={val}/>}
              </TableColumn>
              <TableColumn>
                {val.field.type === "radio" && <Radio value={val}/>}
              </TableColumn>
            </TableRow>
          )
        })
      }
    </Table>
  );
}

function Radio({value}) {
  return (
    <>
      {
        value.field.options.map((val, i) => {
          return (
            <div key={uuidv4()}>
              <input type="radio" name={value.id} id={`${value.id}_${i+1}`} value={val}/>
              <label htmlFor={`${value.id}_${i+1}`}>{val}</label>
            </div>
          )
        })
      }
    </>
  )
}

function Check({value}) {
  return (
    <>
      <input type="checkbox" name={value.id} id={`${value.id}_0`}/> <label htmlFor={`${value.id}_0`}>{value.label}</label> 
    </>
  )
}

function Text({value}) {
  return (
    <>
      <input type="text" name={value.id} id={`${value.id}_0`}/>
    </>
  )
}

export default function fixedgrid(value) {
  return (
    <FixedGrid key={uuidv4()} value={value}/>
  )
}