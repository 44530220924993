import React, { useState, useEffect, useRef } from 'react';
import styled, {keyframes} from 'styled-components';
import Navbar from '../Common/Navbar/Navbar';
import checkIfAuthed from '../Common/utilFuncs/checkIfAuthed';
import { v4 as uuidv4 } from 'uuid';
var Spinner = require('react-spinkit');

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: calc(100vh - var(--navbarHeight));
`

const SettingsContainer = styled.div`
  width: 300px;
  height: 550px;
  overflow-y: scroll;
  background-color: var(--clr-neutral-100);
  color: var(--clr-neutral-900);
  padding: 10px;
`

const SecHeader = styled.h2`
  text-align: center;
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  height: max-content;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-flow: column;
  height: 1.25em;
  width: 100%;
  overflow: hidden;
  background-color: #dcdcdc;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  grid-auto-flow: column;
  height: max-content;
  width: 100%;
  overflow: hidden;
  margin: .25em auto;

  * {
    padding: 0;
    margin: 0 5px;
  }
`

const TableColumn = styled.div`
  overflow-x: scroll;
`

const TableAdd = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-auto-flow: column;
  height: max-content;
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin: .25em auto;

  * {
    padding: 0;
    margin: 0;
  }
`

const InputContainer = styled.div`
  max-width: 100%;
  margin: 0 5px;

  input {
    width: 100%;
    display: block
  }
`

const ButtonBox = styled.div`
  margin: 10px auto;
  width: 100%;
  text-align: center;
`

const ButtonAnimation = keyframes`
  to {
    background-color: var(--clr-neutral-100);
    color: var(--clr-neutral-900);
    border: 1px solid var(--clr-neutral-900);
  }
`

const Button = styled.button`
  background-color: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  border-radius: .25em;
  border: none;
  padding: .5em 1.25em;

  :hover {
    cursor: pointer;
    
    animation-name: ${ButtonAnimation};
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-direction: normal;
  }
`

function RespTecnici({ respTecnici, aggiungi }) {
  const Nome = useRef(null);
  const Email = useRef(null);

  return (
    <div>
      <SecHeader>Responsabili Tecnici</SecHeader>
      <Table>
        <TableHeader>
          <p><b>Nome</b></p>
          <p><b>E-mail</b></p>
        </TableHeader>
        {
          respTecnici.map(respTecnico => {
            return (
              <TableRow key={uuidv4()}>
                <TableColumn>
                  <p>{respTecnico.username}</p>
                </TableColumn>
                <TableColumn>
                  <p>{respTecnico.email}</p>
                </TableColumn>
              </TableRow>
            )
          })
        }
        <TableAdd>
          <InputContainer>
            <input type="text" ref={Nome} />
          </InputContainer>
          <InputContainer>
            <input type="text" ref={Email} />
          </InputContainer>
        </TableAdd>
      </Table>
      <ButtonBox>
        <Button onClick={() => { aggiungi(Nome.current.value, Email.current.value) }}>Aggiungi</Button>
      </ButtonBox>
    </div>
  )
}

function VisuraCamerale() {
  const [visuraCamerale, setVisuraCamerale] = useState(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    fetch("/api/company/single", {method: 'GET'})
      .then(res => res.json())
      .then(res => setVisuraCamerale(res.visuracamerale));
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = null;
    }
  }, [])

  function handleClick() {
    hiddenFileInput.current.click();
  }

  async function handleChangeVisuraCamerale() {
    function readFileAsync(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
	
				reader.onload = () => {
					resolve(reader.result)
				}
	
				reader.onerror = reject;
	
				reader.readAsDataURL(file);
			})
		}
	
		const dataURL = await readFileAsync(hiddenFileInput.current.files[0]);

    fetch("/api/company", 
      {
        method: "PATCH", 
        body: JSON.stringify({visuracamerale: dataURL}),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(() => { setVisuraCamerale(dataURL) })
  }

  if (visuraCamerale) {
    return (
      <div>
        <SecHeader>Visura Camerale</SecHeader>
        <iframe src={visuraCamerale} width="275px" height="300px" title="visuraCamerale" />
        <ButtonBox>
          <Button onClick={handleClick} type="button">Seleziona nuova visura camerale.</Button>
          <input accept="application/pdf" onChange={handleChangeVisuraCamerale} ref={hiddenFileInput} type="file" style={{display: "none"}}/>
        </ButtonBox>  
      </div>
    );
  } else {
    return (
      <div>
        <SecHeader>Visura Camerale</SecHeader>
        <div style={{height: "200px", display: "grid", placeItems: "center"}}>
          <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" />
        </div>
      </div>
    )
  }
}

export default function Account() {
  const [user, setUser] = useState(null);
  const [respTecnico, setRespTecnico] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userObj = await checkIfAuthed();
      setUser(userObj)
    }
    fetchUser();

    fetch("/api/respTecnico").then(res => res.json())
      .then(res => {
        setRespTecnico(res);
      })
  }, [])

  function aggiungiRespTecnico(Nome, Email) {
    if (!Nome) {
      alert("Inserisci nome");
      return;
    }

    if (!Email) {
      alert("Inserisci E-mail");
      return;
    }

    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(Email)) {
      alert("Inserici una E-mail valida")
      return;
    }

    fetch("/api/respTecnico", {
      method: 'POST',
      body: JSON.stringify({
        username: Nome,
        email: Email
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        setRespTecnico([...respTecnico, { username: Nome, email: Email }])
      })
  }

  if (user && respTecnico) {
    return (
      <>
        <Navbar username={user.username} />
        <Container>
          <SettingsContainer>
            <RespTecnici respTecnici={respTecnico} user={user} aggiungi={aggiungiRespTecnico} />
            <VisuraCamerale />
          </SettingsContainer>
        </Container>
      </>
    )
  } else {
    return (
      <>
        <Navbar />
        <Container>
          <SettingsContainer style={{ display: 'grid', placeItems: 'center' }}>
            <Spinner name="ball-clip-rotate-multiple" color="#e84e4e" />
          </SettingsContainer>
        </Container>
      </>
    );
  }
}
